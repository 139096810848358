.MuiFormControl-root .MuiSvgIcon-root{
    display: inline-block;
    width: 28px;
    height: 28px;
    top: 0px;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input{
    padding-left: 0px !important;
    padding-right: 24px !important;
    padding-top: 4px !important;
    padding-bottom: 5px !important;
}