.specifications-table{
    border: 1px solid;
    border-collapse: collapse;
}

.specifications-table td, .specifications-table th{
    border: 1px solid #ccc;
    padding: 8px 16px;
    text-align: left;
}

.specifications-table th{
    background-color: #f5f5f5;
}